var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-overlay',{attrs:{"show":_vm.isLoadingData || _vm.isSavingStaffContact}},[_c('b-card',[_c('div',{staticClass:"head-btn mb-1"},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"to":{ name: 'staff-contact'},"variant":"warning"}},[_c('feather-icon',{staticStyle:{"color":"white"},attrs:{"icon":"ArrowLeftIcon"}})],1)],1),_c('ValidationObserver',{ref:"createStaffContact",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-form',{on:{"submit":function($event){$event.preventDefault();return _vm.save($event)}}},[_c('b-row',[_c('b-col',{attrs:{"sm":"6","md":"4"}},[_c('div',{staticClass:"upload__img-wrap justify-content-center"},[_c('div',{staticClass:"upload__img-box"},[_c('span',{attrs:{"href":_vm.sourceFile,"target":"_blank","rel":"noopener noreferrer"}},[(!!_vm.sourceFile)?_c('div',{staticClass:"img-bg",style:(("background-image: url('" + _vm.sourceFile + "');"))},[(!!_vm.staffContactData.profilepicture)?_c('a',{staticClass:"upload__img-close",on:{"click":_vm.deleteImageLocal}}):_vm._e()]):_c('div',{staticClass:"default-image"})])])]),_c('div',{staticClass:"d-flex justify-content-center"},[_c('ValidationProvider',{attrs:{"rules":"","name":"imagen de perfil"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{staticClass:"pl-1 pr-1"},[_c('b-form-file',{staticClass:"btn-upload-file",attrs:{"placeholder":"Escoge un archivo o sueltalo aquí...","drop-placeholder":"Suelta el archivo aquí...","accept":".jpg, .png, .jpeg","state":errors[0] ? false : valid ? true : null,"browse-text":"Buscar archivo"},model:{value:(_vm.staffContactData.profilepicture),callback:function ($$v) {_vm.$set(_vm.staffContactData, "profilepicture", $$v)},expression:"staffContactData.profilepicture"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1)]),_c('b-col',[_c('b-row',[_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Nombre","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Nombre")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.staffContactData.name),callback:function ($$v) {_vm.$set(_vm.staffContactData, "name", $$v)},expression:"staffContactData.name"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Apellido","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Apellido")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.staffContactData.lastname),callback:function ($$v) {_vm.$set(_vm.staffContactData, "lastname", $$v)},expression:"staffContactData.lastname"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Puesto","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Puesto")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.staffContactData.jobTitle),callback:function ($$v) {_vm.$set(_vm.staffContactData, "jobTitle", $$v)},expression:"staffContactData.jobTitle"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Puesto en inglés","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('label',[_vm._v("Puesto (Inglés)")]),_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.staffContactData.jobTitleEng),callback:function ($$v) {_vm.$set(_vm.staffContactData, "jobTitleEng", $$v)},expression:"staffContactData.jobTitleEng"}}),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Email","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('span',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Email")]),_vm._v(" "),_c('label',[_vm._v("Mostrar en web")])]),_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},model:{value:(_vm.staffContactData.email),callback:function ($$v) {_vm.$set(_vm.staffContactData, "email", $$v)},expression:"staffContactData.email"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.staffContactData.email,"value":true,"unchecked-value":false},model:{value:(_vm.staffContactData.showEmail),callback:function ($$v) {_vm.$set(_vm.staffContactData, "showEmail", $$v)},expression:"staffContactData.showEmail"}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Teléfono","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('span',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Teléfono")]),_vm._v(" "),_c('label',[_vm._v("Mostrar en web")])]),_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null},on:{"keypress":_vm.formatPhoneNumber},model:{value:(_vm.staffContactData.phone),callback:function ($$v) {_vm.$set(_vm.staffContactData, "phone", $$v)},expression:"staffContactData.phone"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.staffContactData.phone,"value":true,"unchecked-value":false},model:{value:(_vm.staffContactData.showPhone),callback:function ($$v) {_vm.$set(_vm.staffContactData, "showPhone", $$v)},expression:"staffContactData.showPhone"}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{staticClass:"label-text",attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"enlace de reunión","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
var errors = ref.errors;
return _c('b-form-group',{},[_c('span',{staticClass:"d-flex justify-content-between"},[_c('label',[_vm._v("Enlace de reunión")]),_vm._v(" "),_c('label',[_vm._v("Mostrar en web")])]),_c('b-input-group',[_c('b-form-input',{attrs:{"state":errors[0] ? false : valid ? true : null,"placeholder":"https://"},model:{value:(_vm.staffContactData.url),callback:function ($$v) {_vm.$set(_vm.staffContactData, "url", $$v)},expression:"staffContactData.url"}}),_c('b-input-group-append',{attrs:{"is-text":""}},[_c('b-form-checkbox',{attrs:{"disabled":!_vm.staffContactData.url,"value":true,"unchecked-value":false},model:{value:(_vm.staffContactData.showUrl),callback:function ($$v) {_vm.$set(_vm.staffContactData, "showUrl", $$v)},expression:"staffContactData.showUrl"}})],1)],1),_c('b-form-invalid-feedback',[_vm._v(_vm._s(errors[0]))])],1)}}],null,true)})],1),_c('b-col',{attrs:{"md":""}},[_c('div',{staticClass:"mt-1"},[_c('b-form-checkbox',{staticClass:"custom-control-success",attrs:{"value":true,"switch":"","unchecked-value":false},model:{value:(_vm.staffContactData.status),callback:function ($$v) {_vm.$set(_vm.staffContactData, "status", $$v)},expression:"staffContactData.status"}},[_vm._v(" Activo ")])],1)])],1)],1)],1),_c('b-row',[_c('b-col',[_c('div',{staticClass:"float-right"},[_c('b-button',{staticClass:"btn-block",attrs:{"type":"submit","variant":"primary","disabled":invalid}},[_vm._v(" Guardar ")])],1)])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }